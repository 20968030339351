export default [
  {
    title: 'Transfer',
    icon: 'SendIcon',
    route: 'transfer',
    action: 'B2B',
    resource: 'B2BUser',

  },
]
