export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    route: 'dashboard',
    action: 'API_B2B_USER',
    resource: 'AccessBothUser',

  },
]
