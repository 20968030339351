export default [
  {
    header: 'Reports',
    icon: 'PieChartIcon',
    action: 'API_B2B_USER',
    resource: 'AccessBothUser',
    children: [
      {
        title: 'B2B Reports',
        icon: 'DatabaseIcon',
        action: 'API_B2B_USER',
        resource: 'AccessBothUser',
        children: [
          {
            title: 'All Transactions',
            route: 'all-transactions',
            action: 'API_B2B_USER',
            resource: 'AccessBothUser',
          },
          // {
          //     title: 'Transfer',
          //     route: 'transfer-report',
          // },
          // {
          //     title: 'Reload',
          //     route: 'reload-report',
          // },
          {
            title: 'Bill Payment',
            route: 'bill-payment',
            action: 'API_B2B_USER',
            resource: 'AccessBothUser',
          },
          // {
          //     title: 'Pin Report',
          //     route: 'pin-report',
          // },
          // {
          //     title: 'Debit Note',
          //     route: 'debit-note',
          // },
          //  {
          //     title: 'Joinings Income',
          //     route: 'joinings-income',
          // },
          {
            title: 'Archive Transactions',
            route: 'archive-transactions',
            action: 'API_B2B_USER',
            resource: 'AccessBothUser',
          },
        ],
      },
      {
        title: 'Summary Report',
        icon: 'PieChartIcon',
        action: 'API_B2B_USER',
        resource: 'AccessBothUser',
        children: [
          {
            title: 'Summary Info',
            route: 'summary-info',
            action: 'API_B2B_USER',
            resource: 'AccessBothUser',
          },
          {
            title: 'Wallet Statement',
            route: 'wallet-summary',
            action: 'API_B2B_USER',
            resource: 'AccessBothUser',
          },
        ],
      },
    ],
  },
]
